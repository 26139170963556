<script setup lang="ts">
import type { FlashMessage } from '~~/composables/useFlash'

const flash = useFlash()

const state: { flashMsg?: FlashMessage } = reactive({})

displayFlash()
watch(flash.cookie, updateFlash) // For immediate flashes

function updateFlash() {
  if (!flash.cookie?.value || !flash.cookie?.value.immediate) return

  displayFlash()
}

function displayFlash() {
  if (!flash.cookie.value) return
  state.flashMsg = flash.cookie.value

  if (process.client) {
    nextTick(() => {
      flash.clear()
    }) 
  }
}

function clear() {
  state.flashMsg = undefined
  flash.clear()
}
</script>

<template>
  <Callout v-if="state.flashMsg" :classes="'flash' + ` ${state.flashMsg.kind}`">
    {{ state.flashMsg.message }}
    <button @click="clear" class="close icon">
      <Icon name="close" />
    </button>
  </Callout>
</template>

<style scoped lang="scss">
:deep(.flash) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close {
  margin-left: 1.25rem;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}
</style>
